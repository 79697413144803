<template>
    <el-dialog
        width="600px"
        top="40px"
        :title="$t('keys.history')"
        :visible.sync="modalKeyHistory"
        :close-on-click-modal="false"
        :before-close="() => !$wait.is('*') && closeModal()"
        @open="getHistory"
        @closed="clearData"
    >
        <div v-loading="$wait.is('loading_key_history')" class="timeline-container flex justify-center" element-loading-spinner="el-custom-spinner">
            <el-timeline>
                <el-timeline-item
                    v-for="entry in historyData"
                    :key="entry.uuid"
                    color="#84c1ff"
                    :timestamp="entry.updated_at"
                >
                    <p class="flex flex-col">
                        {{ currentHolderFormatter(entry.holder) }}
                        <small v-if="entry.causer" class="text-gray-400 text-xs">{{ $t('keys.by') }} {{ entry.causer }}</small>
                    </p>
                </el-timeline-item>
            </el-timeline>

            <NoDataInformation :data="noDataInformation" waitKey="loading_key_history" />
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="closeModal">{{ $t('common.close') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalKeyHistory: {
            type:    Boolean,
            default: false,
        },
        keyUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            noDataInformation: false,
            historyData:       [],
        };
    },

    methods: {
        async getHistory() {
            this.$wait.start('loading_key_history');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.keyUuid}`, {
                requestId: 'justRequest',
            });

            data.history[data.history.length - 1].holder = `${this.$t('keys.registered_by')} ${data.history[data.history.length - 1].holder}`;

            this.historyData = data.history;
            this.noDataInformation = data.history.length;
            this.$wait.end('loading_key_history');
        },

        closeModal() {
            axios.cancelAll();
            this.$emit('close');
        },

        clearData() {
            this.historyData = [];
        },

        currentHolderFormatter(holder) {
            if (holder === 'office' || holder === 'client') {
                return this.$t(`keys.at_${holder}`);
            }
            return holder;
        },
    },
};
</script>
<style scoped>
.timeline-container {
    min-height: 370px;
}
</style>
